import { graphql } from "gatsby"
import React, { FC } from "react"
import { Layout } from "src/components/Layout"
import { Props } from "./types"
import { usePages } from "src/context/pages"
import Logo from "src/images/structured-data-logo.png"
import * as styles from "./styles.module.scss"
import Link from "src/utils/Link"
import GetStarted from "src/components/Banners/GetStarted"
import CaseStudiesCards from "src/components/CaseStudiesCards"

const Template: FC<Props> = ({
  data: {
    file: { childYaml: texts },
  },
}) => {
  const { currentPath, getPage } = usePages()

  return (
    <Layout
      title={texts.metaTitle}
      description={texts.metaDescription}
      openGraph={{
        title: texts.metaTitle,
        description: texts.metaDescription,
        url: `${process.env.GATSBY_CANONICAL_URL}${currentPath}`,
        images: [
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 800,
            height: 600,
            alt: "Flow logo",
          },
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 900,
            height: 800,
            alt: "Flow logo",
          },
        ],
      }}
    >
      <div className={`content-wrapper ${styles.content}`}>
        <div className={styles.header}>
          <h1 className={styles.title}>{texts.header}</h1>
          <p className={styles.description}>{texts.description}</p>
          <Link
            to={getPage("signup").url}
            className={`button-new button-new--primary`}
          >
            {texts.getStarted}
          </Link>
        </div>

        <CaseStudiesCards />
        <GetStarted />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($ymlPath: String!) {
    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        metaTitle
        metaDescription
        header
        description
        getStarted
      }
    }
  }
`

export default Template
